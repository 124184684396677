




















































































import VButton from '@/components/VButton.vue'
import VTab from '@/components/VTab.vue'
import PhoneIcon from '@/assets/icons/phone.svg'
import WhapsappIcon from '@/assets/icons/whatsapp.svg'
import EmailIcon from '@/assets/icons/email.svg'
import ToasSucces from '@/components/ToasSucces.vue'
import { DateTime } from "luxon";
import Vue from 'vue'
export default Vue.extend({
  components: { VButton, VTab,PhoneIcon, WhapsappIcon, EmailIcon, ToasSucces},
  props: {
    rowData: {
      type: Object,
      default(){
        return {
          meterId:{}
        }
      }
    }
  },

  data(){
    return {
      toast: false,
      sendby:{
        email:'',
        phone:{
          countryCode:'',
          phoneNumber:'',
          tel:''
        }
      },
      btnEmailLoading: false,
      btnSmsLoading: false,
      disabled: false,
      countDown : 60
    }
  },


  computed: {
  },

  methods: {
    countDownTimer() {
      if(this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      }
      else if(this.countDown == 0) {
        this.countDown = 60
      }
    },
    onResendBySmsClick(){
      //
      if (this.disabled) {
        console.log('Can not send sms now');
        return
      }
      const transactionId = this.rowData.billId.transacId
      const params = {transactionId, data: this.sendby.phone}

      console.log('params -->', params);
      this.btnSmsLoading = true
      this.$store.dispatch('services/sendBySms', params)
      .then(response => {
        console.log('sms response ', response);
        this.countDownTimer()
        this.disabled = true;
        this.toast = true
        setTimeout(() => {
          this.toast = false
        }, 5000);
        setTimeout(() => {
          this.disabled = false
        }, 60000);
      })
      .catch(error => {
        console.log('sms error ', error)
      })
      .finally(() => {
        this.btnSmsLoading = false
      })
    },

    onResendByEmailClick(){
      //
      const transactionId = this.rowData.billId.transacId
      const params = {transactionId, data: {email: this.sendby.email}}

      console.log('params -->', params);
      this.btnEmailLoading = true
      this.$store.dispatch('services/sendByEmail', params)
      .then(response => {
        this.toast = true
        setTimeout(() => {
          this.toast = false
        }, 5000);
        console.log('email response ', response);
      })
      .catch(error => {
        console.log('email error ', error);
      })
      .finally(() => {
        this.btnEmailLoading = false
      })
    },

    onTelInput(input: string, {countryCallingCode,nationalNumber}: any){
      this.sendby.phone.countryCode = countryCallingCode
      this.sendby.phone.phoneNumber = nationalNumber
    },

    parseDate(date: any) {
      if (!date) {
        return ''
      }
      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATETIME_SHORT)
    },

    setInitalValue(rowData: any){
      let phone: string = rowData.phone

      if (!phone) {
        return
      }
      if (phone.length === 8) {
        this.sendby.phone.tel = phone;
        this.sendby.phone.phoneNumber = phone
        console.log('initial value ', this.sendby.phone.tel);

        return
      }

      phone = phone.trim().replace(' ','').replace('+', '');
      let countryCodeLength = rowData.countryCode.length
      countryCodeLength--
      // phone = phone.slice(countryCodeLength)
      // TODO: extrat phone number
      this.sendby.phone.tel = phone
      console.log('initial value ', this.sendby.phone.tel);
    }
  },

  mounted(){
    console.log('rowData ', this.rowData);
    this.setInitalValue(this.rowData)
  }

})
