





























import Vue from 'vue'
import MenuVerticalIcon from '@/assets/icons/menu-vertical.svg'
import EyeBlueIcon from '@/assets/icons/eye-blue.svg' 
import SendBlueIcon from '@/assets/icons/send-blue.svg'
import RefreshIcon from '@/assets/icons/refresh.svg'
export default Vue.extend({
  components: {
    MenuVerticalIcon,
    EyeBlueIcon,
    SendBlueIcon,
    RefreshIcon
  },

  props: {
    rowData:{
      type: Object,
      default(){
        return {}
      },
    },
    
    onResendClick: {
      type: Function,
      default(rowData: any){
        return rowData
      }
    },

    onShowTransactionClick: {
      type: Function,
      default(rowData: any){
        return rowData
      }
    },

    onGenerateCodeClick: {
      type: Function,
      default(rowData: any){
        return rowData
      }
    },
  },

  data(){
    return {
      open: false
    }
  }
})
