








import Vue from 'vue'
export default Vue.extend({
  props: {
    css:{
      type: Object,
      default(){
        return {
          wrapperClass: 'flex bg-white my-2 p-2',
          activeClass: 'bg-secondary-100 text-white',
          disabledClass: 'disabled',
          pageClass: 'text-black mx-2 flex justify-center items-center rounded-full w-5 text-base h-5',
        }
      }
    }
  },

  data(){
    return {
      pagination:{
        total: 0,
        last_page: 0,
        current_page: 0
      }
    }
  },

  methods:{
    loadPage(page: number){
      this.pagination.current_page = page;
      this.$emit('pagination:change', page)
    },

    setPagination(pagination: any){

      this.pagination = pagination
      this.isActive(this.pagination.current_page)
    },

    currentPage(){
      return this.pagination.current_page;
    },

    isActive(page: number){
      return this.pagination.current_page === page;
    }
  },

  computed: {
    totalPage(){
      return this.$data.pagination.last_page
    },
    
  }

})
