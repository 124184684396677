


























































































import { PaymentSource, PaymentState, VendingState } from '@/interfaces'
import StsIcon from '@/assets/icons/stsIcon.svg'
import MomoIcon from '@/assets/icons/momo.svg'
import BankIcon from '@/assets/icons/bank.svg'
import { DateTime } from "luxon";
import Vue from 'vue'
export default Vue.extend({
  props: {
    rowData: {
      type: Object,
      default(){
        return{}
      }
    }
  },
  components: {
    StsIcon,
    BankIcon,
    MomoIcon,
  },
  data(){
    return {
      pState: PaymentState,
      vState: VendingState,
    }
  },


  computed: {


    paymentSource(): any{
      if (this.rowData.paymentSource == 'MOBILE_MONEY'){
        return {icon: 'MomoIcon', source: 'Mobile Money'}
      }
      return {icon: 'BankIcon', source: 'Virement Bancaire'}
    },
    statusOfVending(): string{
      let value: string = "";
      let classes: string = ""
      if (this.rowData.vendingStatus == this.vState.SUCCESS && !this.rowData.codeStsUsed) {
        classes = 'font-bold p-1 px-2.5 rounded-full state text-green-200 text-sm'
        value = 'Délivré'
      }
      else if (this.rowData.vendingStatus == this.vState.SUCCESS && this.rowData.codeStsUsed){
        classes = 'font-bold p-1 px-2.5 rounded-full bg-blue-80 text-warning-30 text-sm'
        value = 'Utilisé'
      }
      else if (this.rowData.vendingStatus != this.vState.SUCCESS){
        classes = 'font-bold p-1 px-2.5 rounded-full bg-secondary-400 text-secondary-100 text-sm'
        value = 'Non-généré'
      }
      const div = `<span class="${classes}"> ${value} </span>`;
      return div
    }
  },

  methods: {
    formatDate(date: any): string{
      if (!date) {
        return ''
      }
      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATETIME_SHORT)
    },

    onResendCodeClick(): void{
      this.$parent.$emit('resend', this.rowData)
    },
    isMobileMoney(source: string): boolean {
      return source === PaymentSource.MOMO
    }
  }
})
