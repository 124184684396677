



















import Vue from 'vue'
export default Vue.extend({
  props: {
    rowData: {
      type: Object,
      default(){
        return {}
      }
    }
  },

  data(){
    return {
      loading: false,
      message: ''
    }
  },

  methods: {
    onGenerateCodeClick(rowData: any){
      // TODO: generate send regenate code request

      this.loading = true;
      if (this.message) {
        this.message = ''
      }
      this.$store.dispatch('transactions/regenerateCode', {id: rowData._id})
      .then(response => {
        console.log('response', response);
        this.$parent.$emit('close')
      })
      .catch(error => {
        const data = error.data
        if (data.state) {
          switch (data.state) {
            case "-81005":
              this.message = "La transaction a été soumise, veuillez patienter..."
              break;

            default:
              this.message = data.message
              break;
          }
        }

      })
      .finally(() => {this.loading = false})
    }
  }
})
