



















































































































import Vue from 'vue'
import SearchIcon from '@/assets/icons/search.svg'
import EyeBlueIcon from '@/assets/icons/eye-blue.svg'
import SendBlueIcon from '@/assets/icons/send-blue.svg'
import RefreshIcon from '@/assets/icons/refresh.svg'
import VTable from '@/components/VTable/VTable.vue'
import ModalTransactionDetail from './components/ModalTransactionDetail.vue'
import ModalResend from './components/ModalResend.vue'
import ModalGenerateCode from './components/ModalGenerateCode.vue'
import { EMPTY_TABLE_MESSAGE, PaymentState, SOCKET_EVENTS, VendingState } from '@/interfaces'
import { DateTime } from "luxon";
import TablePagination from '@/components/TablePagination.vue'
import OperationMobileTableMenu from './components/OperationMobileTableMenu.vue'
import StateFilter from '@/components/StateFilter.vue'
import VTablePagination from '@/components/VTable/VTablePagination.vue'
import { socket } from '@/plugins'

export default Vue.extend({
  components:{
    SearchIcon,
    EyeBlueIcon,
    SendBlueIcon,
    RefreshIcon,
    ModalTransactionDetail,
    ModalResend,
    ModalGenerateCode,
    VTable,
    TablePagination,
    OperationMobileTableMenu,
    StateFilter,
    VTablePagination
  },

  data(this: any){
    return {
      search: '',
      appendParams: {},
      emptyDataMessage: EMPTY_TABLE_MESSAGE.default,
      columns: [
        {
          name: 'meterId.number',
          title: 'Compte'
        },
        {
          name: 'paymentDate',
          title: 'Date',
          transform({paymentDate}: any){
            if (!paymentDate) {
              return ''
            }
            const dt = DateTime.fromISO(paymentDate)
            return dt.toLocaleString(DateTime.DATETIME_SHORT)
          }
        },
        {
          name: 'meterId.owner',
          title: 'Propriété',
          transform({meterId, billId}: any){
            if (!meterId) {
              if (!billId) {
                return ''
              }
              return billId.meterOwner || ''
            }
            
            return meterId.owner
          }
        },
        {
          name: 'status',
          title: 'Statut du paiement',
          transform:(rowData: any) => {
            const classes = {
              'Succès': 'font-bold p-1 px-2.5 rounded-full state text-green-200 text-sm'
            }
            return this.formatPaymentStatus(rowData, classes)
          }
        },
        {name: 'vendingStatus',
        title: 'Statut de recharge',
        transform: (rowData: any) => {
          const vendingClass = {
              'Délivré': 'font-bold p-1 px-2.5 rounded-full state text-green-200 text-sm',
              'Utilisé': 'font-bold p-1 px-2.5 rounded-full bg-blue-80 text-warning-30 text-sm',
              'Non-généré': 'font-bold p-1 px-2.5 rounded-full bg-secondary-400 text-secondary-100 text-sm'
            }
          return this.formatVendingStatus(rowData, vendingClass)}
        },
        {name: '__slot:action', width: '244px'}
      ],

      columnsMobile: [
        {
          name: 'meterId.number',
          title: 'Transaction Statut',
          transform: (rowData: any) => {
            const paymentClass = {
              'Succès': 'font-bold px-2.5 rounded-full text-green-200 text-sm'
            }
            const vendingClass = {
              'Délivré': 'font-bold px-2.5 rounded-full  text-green-200 text-sm',
              'Utilisé': 'font-bold px-2.5 rounded-full text-warning-30 text-sm',
              'Non-généré': 'font-bold  px-2.5 rounded-full text-secondary-100 text-sm'
            }
            const div =
            `
            <div class="mb-3">
              <div class="">
                <div class="mr-1">${rowData.meterNumb} </div>
                <div class="font-semibold">${rowData.meterId ? rowData.meterId.owner: rowData.billId ? rowData.billId.meterOwner || '': '' } </div>
              </div>
              <div class="flex items-center mb-1">
                <span class="mr-1 ">Paiement: </span>
                ${this.formatPaymentStatus(rowData, paymentClass)}
              </div>
              <div class="flex items-center mb-1">
                <span class="mr-1 ">Recharge: </span>
                ${this.formatVendingStatus(rowData, vendingClass)}
              </div>
            </div>
            `
            return div
          },
        },
        {name: '__slot:action',title: '', width: '40%'}
      ],
      transations: [],
      modalActionComponent: {
        name: 'ModalGenerateCode',
        props:{}
      },
      modalSize: 'md',
      showModalAction: false
    }
  },

  methods: {
    generateFakeData(){
      // const data =
    },

    onResetFilted(this: any){
      // if (Object.prototype.hasOwnProperty.call(this.appendParams, 'rechargeStatus')) {
      //   delete this.appendParams.rechargeStatus
      // }
      this.appendParams.rechargeStatus = ''

      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      this.refreshTables()
    },


    onPaginationData(paginationData: any){
      console.log('Operation paginationData: ====>', paginationData);
      //@ts-ignore
      this.$refs.tablePagination.setPagination(paginationData)
    },


    onChangePage(page: number | string) {
      console.log('change-page', page);

      //@ts-ignore
      this.$refs.table.changePage(page)
    },

    onFilteredState(this: any, event: any){
      //
      console.log('event ', event);
      this.appendParams.rechargeStatus = event
      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.filter
      this.refreshTables()
    },



    onSearch(this: any, search: string){
      // console.log('search',search);
      this.appendParams.search = search;
      if (search.length > 0) {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.search
      }else {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      }
      this.refreshTables()
    },

    onShowTransationDetailClick(this: any, rowData: any) {
      this.modalSize = 'md';
      this.modalActionComponent = {
        name: "ModalTransactionDetail",
        props: {
          rowData,
        }
      };
      this.showModalAction = true
    },

    onRegenerateCodeClick(this: any, rowData: any){
      this.modalSize = 'sm';
      this.modalActionComponent = {
        name: "ModalGenerateCode",
        props: {
          rowData,
        }
      };
      this.showModalAction = true
    },

    onResendClick(this: any, rowData: any){
      this.modalSize = 'md';
      this.modalActionComponent = {
        name: "ModalResend",
        props: {
          rowData,
        }
      };
      this.showModalAction = true
    },

    formatPaymentStatus({status}: any, classes: any) {
      let value: string = "";
      switch (status) {
        case PaymentState.SUCCESS:
          value = 'Succès'
          break;

        default:
          break;
      }
      const div = `<span class="${classes[value]}"> ${value} </span>`;
      return div
    },

    formatVendingStatus({vendingStatus, codeStsUsed}: any, classes: any) {
      let value: string = "";
      if (vendingStatus == VendingState.SUCCESS && !codeStsUsed) {
        value = 'Délivré'
      }
      else if (vendingStatus == VendingState.SUCCESS && codeStsUsed){
        value = 'Utilisé'
      }
      else if (vendingStatus != VendingState.SUCCESS){
        value = 'Non-généré'
      }
      const div = `<span class="${classes[value]}"> ${value} </span>`;
      return div
    },

    loadTransationOnApiMode(this: any, {options}: any){
      const params = options['params']
      console.log('params -->', params);

      return new Promise((resolve, reject) => {
        this.$store.dispatch('transactions/getAllTransaction', params)
        .then((response: any) => {
          console.log('transaction ', response);


          // this.transations = response.data
          resolve({data: response})
        })
        .catch((error: any) => {
          console.log('getAllTransaction ', error);
          reject(error.statusText)
        })
      })
    },

    refreshTables(){
      //@ts-ignore
      this.$refs.table.refresh();
      //@ts-ignore
      this.$refs.mobileTable.refresh()
    },
  },

  computed: {
    filterItems(){
      return [
        {
          name: "Non-généré",
          key: 'nogenerate',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
        {
          name: 'Délivré',
          key: 'delivered',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
        {
          name: 'Utilisé',
          key: 'used',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
      ]
    }
  },

  mounted() {
    //
    socket.on(SOCKET_EVENTS.VENDING_SUCCESS, (data) => {
      console.log('vending success event: ', data);
      console.log('refresh operation table ...')
      this.refreshTables()
    })
  }
})
