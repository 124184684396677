var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tabs.length > 0)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-header"},[_c('div',{staticClass:" bg-blue-70 rounded-xlg rounded-2xl p-2"},[_c('div',{staticClass:"flex h-full justify-between"},_vm._l((_vm.tabs),function(tab,i){return _c('div',{key:i,class:['tab-header-item', ("w-1/" + _vm.chunck)],on:{"click":function($event){return _vm.changeTab(tab)}}},[_c('div',{class:[
                'py-2.5',
                'text-xs md:text-sm',
                'px-2 md:px-4',
                'cursor-pointer',
                tab.text || 'justify-center',
                ("" + (tab.icon ? 'flex items-center': '')),
                _vm.isActiveTab(tab.name)
                  ? 'bg-white rounded-lg font-display font-bold translate-x-3 text-tex-1 shadow-light'
                  : 'unactive text-grey-30 hover:text-black'
              ]},[(tab.icon)?_c('div',[_vm._t(((tab.name) + "-icon"))],2):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(tab.title))])])])}),0)])]),_c('div',{staticClass:"tab-body"},[_c('div',{staticClass:"mt-1"},_vm._l((_vm.tabs),function(tab,i){return _c('div',{key:i},[_c('keep-alive',[(_vm.isActiveTab(tab.name))?_vm._t(tab.name):_vm._e()],2)],1)}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }