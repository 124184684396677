
















































import Vue from "vue";
export default Vue.extend({
  props: {
    tabs: {
      type: Array,
      default(): any[] {
        return [];
      }
    },

    defaultIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentTab: this.tabs.length > 0 ? this.tabs[this.defaultIndex] : {}
    };
  },
  methods: {
    isActiveTab(name: string) {
      return this.$data.currentTab.name === name;
    },
    changeTab(tab: any) {
      this.currentTab = tab;
    }
  },
  computed: {
    chunck() {
      return this.tabs.length;
    }
  },
  mounted() {
    // console.log("currentTab", this.currentTab);
  }
});
